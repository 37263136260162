import { Injectable }         from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface BrowserState {
  theme: string;
  currentPath: string;
}

export function createInitialState(): BrowserState {
  return {
    theme: 'light',
    currentPath: '',
  }
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'browser' })
export class BrowserStore extends Store<BrowserState> {
  constructor() {
    super(createInitialState());
  }
}
